import Vue from "vue";
import VueRouter from "vue-router";

import Open from "../views/Open.vue";
import Dashboard from "../components/Dashboard.vue";
import MarketDashboard from "../components/MarketDashboard.vue";
import History from "../components/History.vue";
import FundHistory from "../components/FundHistory.vue";
import BuySell from "../components/BuySell.vue";
import SetupProfile from "../components/modals/SetupProfile.vue";
import Kyc from "../components/modals/Kyc.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Open",
    component: Open,
    props: true,
  },
  {
    path: "/:pair",
    name: "Dashboard",
    component: Dashboard,
    props: true,
  },
  {
    path: "/profile/setup",
    name: "SetupProfile",
    component: SetupProfile,
    props: true,
  },
  {
    path: "/profile/kyc",
    name: "Kyc",
    component: Kyc,
    props: true,
  },
  {
    path: "/dashboard/:pair",
    name: "MarketDashboard",
    component: MarketDashboard,
    props: true,
    },

  {
    path: "/trade/history",
    name: "History",
    component: History,
    props: true,
  },

  {
    path: "/fund/history",
    name: "FundHistory",
    component: FundHistory,
    props: true,
  },

  {
    path: "/buy-sell/:pair",
    name: "BuySell",
    component: BuySell,
    props: true,
  },
 

];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;