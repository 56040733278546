<template>
  <div class="main">
    
   
    <router-view></router-view>
    
    <Footer/>
  </div>
</template>
<script>

import Footer from "./Footer.vue"




export default {
  name: "Open",
  components: {
    
    Footer
  },
  methods:{
     defaultPair(){
       this.$router.push({name:'Dashboard', params: {pair:'BLXUSDT'}})
     }
  },
  mounted(){
    this.defaultPair();
  }
};
</script>
<style >
 .main{
    width: 100%;
    height: 100vh !important;
    background-size: 100% 100%;
    padding-bottom: 20px;
    background: rgb(2,11,54);
    background-image: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
    
  }
  @media screen and (max-width: 768px) {
    .main{
      height: 100% !important;
      padding-bottom: 20px;
    }
  }
</style>
