<template>
  <div id="app">
   
    <router-view></router-view>

  <!--   <Login  @close="openLogin" v-if="showLoginModal"/> -->

  </div>

  

</template>

<script>

/*import Login from '@/components/modals/Login.vue'*/
export default {
  name: 'App',
  components: {
    //Login,
  },
  data(){
        return{
          showLoginModal: false
        }
  },
  methods:{
    openLogin(){
        window.location.reload();
      },

      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => {  
                this.inactivityTime();
            }).catch((err) => {
                  
            })
      },

      logout: function () {
            // your function for too long inactivity goes here
            // e.g. window.location.href = 'logout.php';
          console.log("logout");

          this.axios.get('/cx/logout')
              .then((response) => { 
                
                this.showLoginModal = true;
                  
              }).catch((err) => { 
                console.log(err)

              })
        },

      resetTimer:  function () {
        var t;
          console.log("active")
          let timeInMinutes = 30;
            clearTimeout(t);
            t = setTimeout(this.logout, timeInMinutes  * 60 * 1000);  // time is in milliseconds
        },
    inactivityTime () {
      
         
        window.onload = this.resetTimer;
        window.onmousemove = this.resetTimer;
        window.onmousedown = this.resetTimer;  // catches touchscreen presses as well      
        window.ontouchstart = this.resetTimer; // catches touchscreen swipes as well      
        window.ontouchmove = this.resetTimer;  // required by some devices 
        window.onclick = this.resetTimer;      // catches touchpad clicks as well
        window.onkeydown = this.resetTimer;   
        window.addEventListener('scroll', this.resetTimer, true); // improved; see comments

        

        
    }
  },
   mounted(){
        this.checkSession();
      }
}
</script>

<style>
@font-face {
    font-family: MSR-Reg;
    src: url(./assets/font/Montserrat-Regular.otf);
  }

  @font-face {
    font-family: MSR-Bold;
    src: url(./assets/font/Montserrat-Bold.otf);
  }

  @font-face {
    font-family: MSR-Medium;
    src: url(./assets/font/Montserrat-Medium.otf);
  }

  @font-face {
    font-family: MSR-SemiBold;
    src: url(./assets/font/Montserrat-SemiBold.otf);
  }
  html,body {
        background-color: #011434;
  }
  .alert-danger {
    color: #580a00;
    background-color: #f7c7c2;
    border:2px solid;
    border-color: #580a00;
    padding:15px;
    border-radius:10px;
  }

</style>
