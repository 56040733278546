<template>
<div class="main">
   <PageLoader v-if="showLoading"/>
   <nav class="navbar navbar-light">
        <div class="container-fluid col-12">
            <div class="navbar-brand"><img src="@/assets/logo.png" alt="HOME" title="HOME"></div>
            
              <div class="d-flex my-2 mx-2 my-lg-0">
                <div class="dropdown">
                   <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="control">
                        <a class="button">
                         <b-icon icon="person-fill"> </b-icon>
                          {{ init.user.fullName }}
                        </a>   
                    </span><span class="caret"></span></button>
                    <div class="dropdown-content">

                      <div>
                        <a href="javascript:;" class="a-text" v-on:click="openDeposit">Deposit</a>
                      </div>

                      <div>
                        <a href="javascript:;" class="a-text" v-on:click="openWithdraw">Withdraw</a>
                      </div>
                       <div>
                        <a href="javascript:;" class="a-text" v-on:click="openProfile">Profile</a>
                      </div>

                      <div>
                        <a href="javascript:;" class="a-text" v-on:click="openHistory">Trade History</a>
                      </div>

                      <div>
                        <a href="javascript:;" class="a-text" v-on:click="openFundHistory">Funds History</a>
                      </div>
                       <div>
                        <a href="javascript:;" class="a-text" v-on:click="changePin">Change PIN</a>
                      </div>

                      <div>
                        <a href="#" class="a-text" v-on:click="openLogout">Log Out</a>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          </nav>
      <div id="main-body">
        <div class="row pb-5">
          <div class="column">
                <div class="card"  v-for="balance in init.balance" v-if="balance.currency != 'PHP'" :key="balance.id">
 
                    <p>Available Balance in {{balance.currency}}:</p>
                    <p>{{balance.availableBalance}}</p>
                  
                  
                </div>
          </div>
          
       </div>
           <div id="main-topbar" class="d-flex flex-wrap">
                <div class="topbar-div my-auto mr-5">
                    <b-form-select v-model="marketPair" class="mb-3 pair-select">
                      <b-form-select-option v-for="pair in pairs" :key="pair.id" :value="pair">{{pair.label}}</b-form-select-option> 
                    </b-form-select>
                    <h6>{{ init.currency.toCurrency }} Price</h6>
                </div>
                <div class="topbar-div my-auto mr-5">
                    <h4>Price</h4>
                    <h6> {{ marketPrice }} {{ init.currency.fromCurrency }} </h6>
                </div>
                <div class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h Change</h6>
                    <h6 class="purple-text"> {{ marketDetails.percent_change_24 != undefined ? marketDetails.percent_change_24 : '0.00' }} %</h6>
                </div>
                <div class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h High</h6>
                    <h6>{{ marketDetails.high != undefined ? marketDetails.high : '0.00' }}</h6>
                </div>
                <div  class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h Low</h6>
                    <h6>{{ marketDetails.low != undefined ? marketDetails.low : '0.00' }}</h6>
                </div>
                <div  class="topbar-div my-auto mr-5">
                    <h6 class="small-header">24h Volume({{ init.currency.toCurrency }})</h6>
                    <h6>{{ marketDetails.volume != undefined ? marketDetails.volume : '0.00'}}</h6>
                </div>
               
            </div>
          
    
  
  <div  class="d-flex main-container">
        <div id="main-left">
            <div >
                <div id="left-bars">
                    <div id="left-bar-1">
                      <label>Complete Buy Orders</label>
                        <div class="d-flex justify-content-between">
                          
                          <table>
                            <thead>
                              <tr>
                                <th>Price {{ init.currency.fromCurrency }}</th>
                                <th>Amount {{ init.currency.toCurrency }}</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody> 
                              <tr v-for="data in completedBuyOrders">
                                <td>{{ parseFloat(data.xchgRate).toFixed(4) }}</td>
                                <td>{{ parseFloat(data.targetAmount).toFixed(8) }}</td>
                                <td>{{ parseFloat(data.sourceAmount).toFixed(8) }}</td>
                              </tr>

                              <tr v-if="completedBuyOrders.length == 0">
                                <td colspan="3">No Data found</td>
                              </tr>
                            </tbody>
                          </table>
                         
                        </div>
                    </div>
                    <div id="left-bar-1" class="mt-3">
                      <label>Complete Sell Orders</label>
                        <div class="d-flex justify-content-between">
                           
                            <table>
                            <thead>
                              <tr>
                                <th>Price {{ init.currency.fromCurrency }}</th>
                                <th>Amount {{ init.currency.toCurrency }}</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody> 
                              <tr v-for="data in completedSellOrders">
                                <td>{{ parseFloat(data.xchgRate).toFixed(4) }}</td>
                                <td>{{ parseFloat(data.sourceAmount).toFixed(8) }}</td>
                                <td>{{ parseFloat(data.targetAmount).toFixed(8) }}</td>
                              </tr>

                              <tr v-if="completedSellOrders.length == 0">
                                <td colspan="3">No Data found</td>
                              </tr>
                            </tbody>
                          </table>


                        </div>
                    </div>
                </div>
              </div>
                
            </div>

            <div id="main-center" >
                  <div id="main-center-top">

                 <!--   <div  id="chartContainer" v-if="$route.params.pair == 'BLXUSDT'" style="height: 450px; width: 100%; "></div>

 -->
                  <!--   <div > -->
                      <div id="tradingview"  ></div>  
                 <!--    </div> -->



                  </div>
                  <div id="main-bottom">
                    <div class="btn-group buy-or-sell">
                      <button type="button" class="btn" :class="{active: buyOrSell == 'buy'}" @click="toggleBuyOrSell('buy')">BUY</button>
                      <button type="button" class="btn" :class="{active: buyOrSell == 'sell'}" @click="toggleBuyOrSell('sell')">SELL</button> 
                    </div>
                    <div class="d-flex flex-wrap">
                      <b-dropdown id="dropdown-1" :text="buyOrSellState" class="m-md-2" variant="none" toggle-class="dropdownBtn" style="background:none;color:white;border-radius:5px;">
                        <b-dropdown-item @click="switchState('Limit')">Limit</b-dropdown-item>
                        <b-dropdown-item @click="switchState('Market')">Market</b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <div class="danger alert-danger text-center" v-if="errors.buyOrSell != ''">
                        {{ errors.buyOrSell }}
                    </div>

                    <div v-if="buyOrSell == 'buy' && buyOrSellState == 'Limit'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="buyValues.orderPrice" @change="calculateBuyValue('price')" placeholder="Order Price" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">{{ init.currency.fromCurrency }}</h6>
                      </div>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="buyValues.quantity" @change="calculateBuyValue('quantity')" placeholder="Quantity" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">{{ init.currency.toCurrency }}</h6>
                      </div>
                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee: {{ fee }}</label>
                        <label v-if="this.init.balance.length > 0">Balance: {{ getBalance(init.currency.fromCurrency) }} {{ init.currency.fromCurrency }} </label>
                      </div>
                      <b-form-input type="range" v-model="buyValues.orderValue" @change="calculateBuyValue('value')" :max="parseFloat(getBalance(init.currency.fromCurrency)).toFixed(8)" step="0.00000001" style="width:100%;"></b-form-input>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="buyValues.orderValue"  @change="calculateBuyValue('value')" placeholder="Order Value" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">{{ init.currency.fromCurrency }}</h6>
                      </div>
                      <button class="btn btn-buy" @click="onBuy">BUY {{ init.currency.toCurrency }} </button>
                    </div>
                    
                    <div v-if="buyOrSell == 'sell' && buyOrSellState == 'Limit'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="sellValues.orderPrice" @change="calculateSellValue('price')" placeholder="Order Price" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">{{ init.currency.fromCurrency }}</h6>
                      </div>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="sellValues.quantity"  @change="calculateSellValue('quantity')" placeholder="Quantity" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">{{ init.currency.toCurrency }}</h6>
                      </div>
                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee: {{ fee }}</label>
                       <label v-if="this.init.balance.length > 0"> Balance: {{ getBalance(init.currency.toCurrency) }} {{init.currency.toCurrency}} </label>
                      </div>
                      <b-form-input type="range" v-model="sellValues.quantity" @change="calculateSellValue('quantity')" :max="parseFloat(getBalance(init.currency.toCurrency)).toFixed(8)" step="0.00000001"  style="width:100%;"></b-form-input>
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="sellValues.orderValue" @change="calculateSellValue('value')" placeholder="Order Value" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto" @click="onSell">{{ init.currency.fromCurrency }}</h6>
                      </div>
                      <button class="btn btn-buy" @click="onSell">SELL {{ init.currency.toCurrency }}  </button>
                    </div>


                    <div v-if="buyOrSell == 'buy' && buyOrSellState == 'Market'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="buyValues.orderValue"  placeholder="Price value" style="margin-right:2em;background:none;border:none;color:white;">

                        <h6 class="field-value my-auto">{{ init.currency.fromCurrency }}</h6>


                      </div>

                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee: {{ fee }}</label>
                        <label v-if="this.init.balance.length > 0">Balance: {{ getBalance(init.currency.fromCurrency) }} {{ init.currency.fromCurrency }} </label>
                      </div>
                      <button class="btn btn-buy" @click="onBuy">BUY {{ init.currency.toCurrency }}</button>
                    </div>
                    <div v-if="buyOrSell == 'sell' && buyOrSellState == 'Market'">
                      <div class="bottom-field d-flex justify-content-between mt-3" id="price-field">
                        <input type="text" class="form-control mr-2" v-model="sellValues.quantity"  placeholder="Quantity" style="margin-right:2em;background:none;border:none;color:white;">
                        <h6 class="field-value my-auto">{{ init.currency.toCurrency }}</h6>
                      </div>
                      <div class="d-flex justify-content-between" style="width:100%;">
                        <label>Fee: {{ fee }}</label>
                        <label v-if="this.init.balance.length > 0">Balance: {{ getBalance(init.currency.fromCurrency) }} {{ init.currency.fromCurrency }} </label>
                      </div>
                      <button class="btn btn-buy" @click="onSell">SELL {{ init.currency.toCurrency }}</button>
                    </div>
                  </div>
                </div>
       
        <div id="main-right">
          <div class="text-center" style="font-weight:bold;">BUY</div>
          <div id="main-buy" class="d-flex justify-content-between">
           

             <table>
                  <thead>
                    <tr>
                       <th class="left-col">Price ({{ init.currency.fromCurrency }})  </th>
                      <th class="right-col">Quantity ({{ init.currency.toCurrency }})</th> 
                    </tr>
                  </thead>

                  <tbody> 
                    <tr v-for="data in pendingBuyOrders">
                      <td class="left-col">{{ parseFloat(data.xchgRate).toFixed(4) }}</td>
                      <td class="right-col">{{ parseFloat(data.remainingOrder).toFixed(8) }}</td> 
                    </tr>

                    <tr v-if="pendingBuyOrders.length == 0">
                      <td colspan="3">No Data found</td>
                    </tr>
                  </tbody>
                </table>
          </div>
          <div class="market-price d-flex justify-content-between flex-wrap">
            <h6 class="my-auto">Market Price: {{ parseFloat(marketPrice).toFixed(4) }} {{ init.currency.fromCurrency }} / {{ init.currency.toCurrency }}</h6>
            <router-link :to="'/buy-sell/' + $route.params.pair" style="cursor:pointer;color:white;font-weight:bold;" class="routelink">More</router-link>
          </div>
          <div class="text-center" style="font-weight:bold;">SELL</div>
          <div id="main-sell" class="d-flex justify-content-between">
            <!-- <div id="sell-price-column" class="text-left">
              <h6 class="sell-price-header">Price</h6>
              <div v-for="h in init.history" :key="h.hash">
                <h6 class="buy-price-text" v-if="h.side == 'S'">{{ h.xchgRate.toFixed(8) }}</h6>
              </div>
            </div>
            <div id="sell-quantity-column">
              <h6 class="sell-quantity-header">Quantity</h6>
              <div v-for="h in init.history" :key="h.hash">
                <h6 class="buy-price-text" v-if="h.side == 'S'">{{ init.currency.fromCurrency }} {{ h.sourceAmount }}</h6>
              </div>
            </div> -->

            <table>
                  <thead>
                    <tr>
                      <th class="left-col">Price ({{ init.currency.fromCurrency }})  </th>
                      <th class="right-col">Quantity ({{ init.currency.toCurrency }})</th> 
                    </tr>
                  </thead>

                  <tbody> 
                    <tr v-for="data in pendingSellOrders">
                      <td class="left-col">{{ parseFloat(data.xchgRate).toFixed(4) }}</td>
                      <td class="right-col">{{ parseFloat(data.remainingOrder).toFixed(8) }}</td> 
                    </tr>

                    <tr v-if="pendingSellOrders.length == 0">
                      <td colspan="3">No Data found</td>
                    </tr>
                  </tbody>
                </table>


          </div>
        </div>
    </div>
</div>

     

          <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
          <DepositModal @close="openDeposit" v-if="showDepositModal"/>
           <Withdraw @close="openWithdraw" v-if="showWithdrawModal"/>
           <Profile @close="openProfile" v-if="showProfileModal" :status="verificationStatus"/>
          <ChangePin @close="changePin" v-if="showChangePinModal"/>
          <Login  @close="openLogin" @openSignUp="openSignup" v-if="showLoginModal"/>
          <Signup  @close="openSignup" @openLogin="openLogin" v-if="showSignupModal"/>
          <Footer style="margin-top: 20px;"/>
        </div>
</template>
<script>

import Footer from '../views/Footer.vue'
import numeral from 'numeral'
import LogoutModal from '../components/modals/LogoutModal.vue'
import DepositModal from '../components/modals/Deposit.vue'
import Withdraw from '../components/modals/Withdraw'
import Login from '../components/modals/Login.vue'
import Signup from '../components/modals/Signup.vue'
import ChangePin from '../components/modals/ChangePin.vue'
import PageLoader from '../components/PageLoader.vue'
import Profile from '../components/modals/Profile.vue'
import CanvasJSChart from '../CanvasJSVueComponent.vue';
import Pusher from 'pusher-js';


export default {
    name: 'MarketDashboard',
    components: {
        Footer,
        LogoutModal,
        DepositModal,
        PageLoader,
        CanvasJSChart,
        Login,
        Signup,
        ChangePin,
        Withdraw,
        Profile
    },
    data(){
        return{
          stockChart: null,
          marketPrice: "",
          marketDetails:{},
          chartResponse: [],
          balance: {

          },
          tradingViewLnk: "",
          showChart: false,
          pendingBuyOrders: [],
          completedBuyOrders: [],
          pendingSellOrders: [],
          completedSellOrders: [],
            showLoading: true,
            showLogoutModal: false,
            showDepositModal: false,
            showWithdrawModal: false,
            showLoginModal: false,
            showSignupModal: false,
            showChangePinModal: false,
            showProfileModal: false,
            currency:'',
            buyOrSell: 'buy',
            buyValues: {
              xchgRate: '',
              orderPrice: '',
              quantity: '',
              slider: '0',
              orderValue: '0',
            },
            sellValues: {
              xchgRate: '',
              orderPrice: '',
              quantity: '',
              slider: '0',
              orderValue: '0',
            },
           
            errors: {
              buyOrSell: "",
              buyQuantity: "",
              sellQuantity: ""
            },
            pairs: [],
            marketPair: {            }
            ,
            buyOrSellState: 'Limit',
            verificationStatus: 'NONE',
            fee: ''
        }
    },
     computed: {
        init() {
            return this.$store.state.init;
        },
      },
   
    methods:{ 
     
      getCompletedHistory: function(){
        const params = {
          status: '00',
          fromDate: '12/01/2022',
          toDate: '12/31/2022'
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          data,
          url: '/cx/trade/history',
        };

        this.axios(options)
          .then((response) => {
              this.init.history = response.data.rowsList
          })
      },

      switchState(state) {
        this.buyOrSellState = state
        this.fee = ''
      },

      formatAmount: function(num){
        return numeral(num).format('0.00000000');
      },

      getPairsDetails: function(){

        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/pub/market/details?market=' + this.marketPair.value,
        };

        this.axios(options)
          .then((response) => {
              this.marketDetails = response.data;
             
            })
      },

      getPairs: function(){

        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/pub/market/pairs',
        };

        this.axios(options)
          .then((response) => {
              
              //console.log(this.marketPair)
              this.pairs = response.data
              response.data.forEach((value) => {

                if(value.value == this.$route.params.pair) {
                  this.marketPair = value
                  this.init.currency.fromCurrency = value.label.substring(value.label.indexOf('/') + 1)
                  this.init.currency.toCurrency = value.label.substring(0, value.label.indexOf('/'))
             

                  this.getPairsDetails()
                }
              })
              this.getRate(); 
              this.getCompletedHistory();
            })
      },

      getFee(amount) {
        let code = ''
        if(this.buyOrSellState == 'Limit') {
          code = 'TRADE_LIMIT'
        } else {
          code = 'TRADE_MARKET'
        }

        let currency = '';

        if(this.buyOrSell == 'sell') {
          currency = this.init.currency.toCurrency;
        } 

        if(this.buyOrSell == 'buy') {
          currency = this.init.currency.fromCurrency;
        }

        let params = {
          feeCode: code,
          amount: amount,
          currency: currency 
        }
        
        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
    
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          data,
          url: '/cx/calctxnfee',
        };

        this.axios(options)
          .then((response) => { 
            console.log(response)
            this.fee = response.data.txnFee
          })
      },

      getRate() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/pub/market/price?market=' + this.$route.params.pair + 
               '&sourceCurrency=' + this.init.currency.toCurrency +
               '&targetCurrency=' + this.init.currency.fromCurrency,
        };

        this.axios(options)
          .then((response) => { 
            this.buyValues.orderPrice = response.data.xchgRate;
            this.sellValues.orderPrice = response.data.xchgRate;
            this.marketPrice = response.data.xchgRate;
          }).catch((err) => {
            
          })
      },


      calculateBuyValue(inputted) {
        
        if(inputted == 'price') {
          if(this.buyValues.quantity == '' && this.buyValues.orderValue != '') {
            this.buyValues.quantity = this.buyValues.orderValue / this.buyValues.orderPrice
           
          } else if(this.buyValues.orderValue == '' && this.buyValues.quantity != '') {
            this.buyValues.orderValue = this.buyValues.quantity * this.buyValues.orderPrice
          } else {
            this.buyValues.orderValue = this.buyValues.quantity * this.buyValues.orderPrice
            this.buyValues.quantity = this.buyValues.orderValue / this.buyValues.orderPrice
          }
        } else if(inputted == 'value') {
          if(this.buyValues.orderPrice != '') {
            this.buyValues.quantity = this.buyValues.orderValue / this.buyValues.orderPrice
            
          } 
        } else if(inputted == 'quantity') {
          if(this.buyValues.orderPrice != '') {
            this.buyValues.orderValue = this.buyValues.quantity * this.buyValues.orderPrice
            
          }
        }

        this.buyValues.quantity = parseFloat(this.buyValues.quantity).toFixed(8);
        this.buyValues.orderValue = parseFloat(this.buyValues.orderValue).toFixed(8);
        this.getFee(this.buyValues.orderValue)
      },

      calculateSellValue(inputted) {
        //this.getFee(this.sellValues.quantity)
        // if(inputted == 'price') {
        //   if(this.sellValues.quantity == '' && this.sellValues.orderValue != '') {
        //     this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
        //     console.log(this.sellValues.quantity)
        //   } else if(this.sellValues.orderValue == '' && this.sellValues.quantity != '') {
        //     this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
        //   } else {
        //     this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
        //     this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
        //   }
        // } else if(inputted == 'value') {
        //   if(this.sellValues.orderPrice != '') {
        //     this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
        //     console.log(this.sellValues.orderValue / this.sellValues.orderPrice)
        //   } 
        // } else if(inputted == 'quantity') {
        //   if(this.sellValues.orderPrice != '') {
        //     this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
        //     console.log(this.sellValues.orderValue)
        //   }
        // }

        if(inputted == 'price') {
          if(this.sellValues.quantity == '' && this.sellValues.orderValue != '') {
            this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
           
          } else if(this.sellValues.orderValue == '' && this.buyValues.quantity != '') {
            this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
          } else {
            this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
            this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
          }
        } else if(inputted == 'value') {
          if(this.sellValues.orderPrice != '') {
            this.sellValues.quantity = this.sellValues.orderValue / this.sellValues.orderPrice
            
          } 
          
        } else if(inputted == 'quantity') {
          if(this.sellValues.orderPrice != '') {
            this.sellValues.orderValue = this.sellValues.quantity * this.sellValues.orderPrice
            
          }
        }

        this.sellValues.quantity = parseFloat(this.sellValues.quantity).toFixed(8);
        this.sellValues.orderValue = parseFloat(this.sellValues.orderValue).toFixed(8);

        this.getFee(this.sellValues.quantity)
      },

      onBuy() {
        let hasError = false;
        if(this.buyValues.quantity == '' || this.buyValues.quantity == undefined || this.buyValues.quantity == null) {
          this.errors.buyQuantity = 'Quantity is required.';
          hasError = true;
        }

        if(!hasError) {
          const options = {
            method: 'POST',
            url: '/cx/txn/trade/buy/init',
          };
          this.axios(options)
            .then((response) => {
              let txnControlId = response.data.txnControlId
              let params = {};

              if(this.buyOrSellState == 'Limit') {
                params = {
                  market: this.$route.params.pair,
                  xchgRate: parseFloat(this.buyValues.orderPrice).toFixed(8),
                  sourceAmount: parseFloat(this.buyValues.orderValue).toFixed(8),
                  sourceCurrency: this.init.currency.fromCurrency,
                  targetCurrency: this.init.currency.toCurrency,
                  txnControlId: txnControlId,
                }
              } else if(this.buyOrSellState == 'Market') {
                 params = {
                  market: this.$route.params.pair, 
                  sourceAmount: parseFloat(this.buyValues.quantity).toFixed(8),
                  sourceCurrency: this.init.currency.fromCurrency,
                  targetCurrency: this.init.currency.toCurrency,
                  txnControlId: txnControlId,
                }
              }
 
 

              const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
          
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                data,
                url: '/cx/txn/trade/buy/submit',
              };

              this.axios(options)
                .then((response) => { 

                   
                  const params = {
                    txnControlId: txnControlId,
                  }

                  const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');
              
                  const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    data,
                    url: '/cx/txn/trade/buy/confirm',
                  };

                  this.axios(options)
                    .then(() => { 
                      this.getFunds();
                      this.errors.buyOrSell = "Order Submitted!"
                      this.buyValues.quantity = ""
                      this.buyValues.orderValue = 0;

                      this.getBuyOrders('11');
                      this.getBuyOrders('00');
                      
                      setTimeout(() => {
                        this.errors.buyOrSell = ''
                      }, 5000)
                    }).catch((err) => {
                      
                    })
                }).catch((err) => {
                  
                  this.errors.buyOrSell = err.response.data.msgText
                  if(err.response.data.fieldErrors) {
                    this.errors.buyOrSell = err.response.data.fieldErrors[0].defaultMessage
                  }

                  setTimeout(() => {
                    this.errors.buyOrSell = ''
                  }, 5000)
                })
            })
            .catch((error) => {
              console.log(error)
            });
          }
      },

      onSell() {
        
        let hasError = false;
        if(this.sellValues.quantity == '' || this.sellValues.quantity == undefined || this.sellValues.quantity == null) {
          this.errors.sellQuantity = 'Quantity is required.';
          hasError = true;
        }

 
        if(!hasError) {
          const options = {
            method: 'POST',
            url: '/cx/txn/trade/sell/init',
          };
          this.axios(options)
            .then((response) => {
              let txnControlId = response.data.txnControlId
              // console.log('.')
              // const params = {
              //   market: this.$route.params.pair,
              //   xchgRate: parseFloat(this.sellValues.orderPrice).toFixed(8),
              //   sourceAmount: parseFloat(this.sellValues.quantity).toFixed(8),
              //   sourceCurrency: this.init.currency.toCurrency,
              //   targetCurrency: this.init.currency.fromCurrency,
              //   txnControlId: txnControlId,
              // }

              let params = {};

              if(this.buyOrSellState == 'Limit') {
                params = {
                  market: this.$route.params.pair,
                  xchgRate: parseFloat(this.sellValues.orderPrice).toFixed(8),
                  sourceAmount: parseFloat(this.sellValues.quantity).toFixed(8),
                  sourceCurrency: this.init.currency.toCurrency,
                  targetCurrency: this.init.currency.fromCurrency,
                  txnControlId: txnControlId,
                }
              } else if(this.buyOrSellState == 'Market') {
                 params = {
                  market: this.$route.params.pair, 
                  sourceAmount: parseFloat(this.sellValues.quantity).toFixed(8),
                  sourceCurrency: this.init.currency.toCurrency,
                  targetCurrency: this.init.currency.fromCurrency,
                  txnControlId: txnControlId,
                }
              }

              const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
          
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                data,
                url: '/cx/txn/trade/sell/submit',
              };

              this.axios(options)
                .then(() => { 
                  const params = {
                    txnControlId: txnControlId,
                  }

                  const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');
              
                  const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                    data,
                    url: '/cx/txn/trade/sell/confirm',
                  };

                  this.axios(options)
                    .then(() => { 
                      this.getFunds();
                      this.errors.buyOrSell = "Order Submitted!"
                      this.sellValues.quantity = ""
                      this.sellValues.orderValue = 0;
                      this.getSellOrders('11');
                      this.getSellOrders('00');

                      setTimeout(() => {
                        this.errors.buyOrSell = ''
                      }, 5000)


                    }).catch((err) => {
                      
                    })
                }).catch((err) => {
                  this.errors.buyOrSell = err.response.data.msgText
                  if(err.response.data.fieldErrors) {
                    this.errors.buyOrSell = err.response.data.fieldErrors[0].defaultMessage
                  }

                  setTimeout(() => {
                    this.errors.buyOrSell = ''
                  }, 5000)
                })
            })
            .catch((error) => {
              
            });
          }
      },

      addColorSet() {
        CanvasJS.addColorSet("purpleShades",
          [//colorSet Array
            "#c906f9",
            "#9999ff"            
          ]);
      },
      toggleBuyOrSell(state) {
        this.buyOrSell = state
      },
      sliderChange(){
        this.buyValues.orderValue = this.buyValues.slider
        this.calculateBuyValue();
      },
       openDeposit: function(){
        this.showDepositModal = !this.showDepositModal

      },
      openWithdraw(){
        console.log(this.init.user.middleName)
         if(this.init.user.verificationStatus != 'VERIFIED') {
          window.location.href="/#/profile/kyc"
        } else {
        this.showWithdrawModal = !this.showWithdrawModal
        }
      },
      openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
      openProfile(){
        if(this.verificationStatus == 'PENDING') {
          window.location.href="/#/profile/kyc"
        } else {
          this.showProfileModal = !this.showProfileModal;
        }
      },
      openHistory() {
        window.location.href = "/#/trade/history"
      },
      openFundHistory() {
        window.location.href = "/#/fund/history"
      },
      changePin() {
        this.showChangePinModal = !this.showChangePinModal;
        
      },
      openLogin(){
        this.showLoginModal = !this.showLoginModal;
        this.showSignupModal = false;
      },
      openSignup(){
        this.showSignupModal = !this.showSignupModal;
        this.showLoginModal = false;
      },
       onChange(event){
          this.currency = event.target.value;
      },
  
        getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
               this.init.user = response.data;
               this.userId = response.data.entityId

               if(this.init.user.middleName != '' && this.init.user.verificationStatus == 'UNVERIFIED') {
                this.verificationStatus = 'PENDING'
               } else if(this.init.user.middleName != '' && this.init.user.verificationStatus == 'VERIFIED') {
                this.verificationStatus = 'VERIFIED'
               }


              })
      },
     checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.getProfile();
              setTimeout( () => {
                this.showLoading = false;
              },1000)
              this.getFunds();
            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                this.showLoading = false;
                this.openLogin();
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/profile/setup";
               },1000)
                
              }  

            })
      },

      getBalance: function(currency){
        this.balance[currency] = {};
        if(this.init.balance.length > 0 ) {
          this.init.balance.forEach((value) => {
            if(currency == value.currency) {
              this.balance[currency] = value;
            }
          })

          if(Object.keys(this.balance[currency]).length === 0 && this.balance[currency].constructor === Object) {
            return parseFloat(0).toFixed(8);
          } else {
            return parseFloat(this.balance[currency].availableBalance).toFixed(8);
          }

        } else {
           return parseFloat(0).toFixed(8);
        }

        

 
      },

      getFunds: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/funds',
        };

        this.axios(options)
           .then((response) => { 

            this.init.balance = response.data;
              /*response.data.forEach((value) => {
                if(this.init.currency.fromCurrency == value.currency) {

                  

                  // if(this.init.balance.currentBalance == 0) {
                  //   this.init.balance.currentBalance = '1'
                  //   console.log('0')
                  // }
                }
              })*/
            }).catch((err) => {
              


            })
      },
      
        logout: function(){
            this.axios.get('/cx/logout')
            .then((response) => { 
              
              window.location.href = "/#/";
                
            }).catch((err) => { 
              console.log(err)

            })
        },
        formatAmount: function(amount) {
          return numeral(amount).format('0,0.00') 
        },
        
      clearTimer(){
       
      },
       reloadPage(){
        window.setTimeout( function() {
            window.location.reload();
          }, 59000);
      },
    


     getBuyOrders: function(status){
           if(status == '00') {
                
                //this.pendingBuyOrders = [];
                
              }

              if(status == '11') {
                // this.completedBuyOrders = [];
                
              }
         const params = {
            status: status,
            itemPerPage: '15',
            market: this.$route.params.pair
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/pub/trade/buy/orders',
          };

          this.axios(options)
            .then((response) => {

              if(status == '00') {
 

                this.pendingBuyOrders = response.data;

                
              }

              if(status == '11') {
                 this.completedBuyOrders = response.data;
                
              }
 
            })
     },
     getSellOrders: function(status){
       if(status == '00') {
                //this.pendingSellOrders = [];
                
              }

              if(status == '11') {
                 //this.completedSellOrders = [];
                
              }
         const params = {
            status: status,
            itemPerPage: '15',
            market: this.$route.params.pair
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/pub/trade/sell/orders',
          };

          this.axios(options)
            .then((response) => {

              if(status == '00') {
                this.pendingSellOrders = response.data;

               
                
              }

              if(status == '11') {
                 this.completedSellOrders = response.data;
                
              }


               
                
            })
     },

     zeroPad: function(num, places) {
      return String(num).padStart(places, '0');
     },

     subscribe () {
      
        let pusher = new Pusher('874b675051ad83bc6d82', { cluster: 'ap3' })
        pusher.subscribe('xcoin');
        pusher.bind('refresh.data', data => {

            if(this.$route.name == 'MarketDashboard') {
              this.getRate(); 
              this.getPairs();
              this.getBuyOrders('11');
              this.getBuyOrders('00');
              this.getSellOrders('11');
              this.getSellOrders('00');
            }
            
            
        })
      },

      changeBorderColor: function (chart){
          var dataSeries;

          
          for( var i = 0; i < chart.options.charts[0].data.length; i++){
              dataSeries = chart.options.charts[0].data[i];



              for(var j = 0; j < dataSeries.dataPoints.length; j++){
                dataSeries.dataPoints[j].color = (dataSeries.dataPoints[j].y[0] <= dataSeries.dataPoints[j].y[3]) ? (dataSeries.risingColor ? dataSeries.risingColor : dataSeries.color) : (dataSeries.fallingColor ? dataSeries.fallingColor : dataSeries.color);

              } 
          }
        },

        rangeChanged: function(e){
          console.log(e)
          var minimum = parseInt(e.minimum / 1000); //Converting milliseconds to seconds
          var maximum = parseInt(e.maximum / 1000); //Converting milliseconds to seconds

          let interval = ((maximum-minimum)/60) < 1 ? 1: (maximum-minimum)/60;

          if(e.index == 11) {

            this.renderChart(false);
          } else {

            
            let fromDate = new Date(this.stockChart.rangeSelector.inputFields.startValue)
            fromDate.setDate(fromDate.getDate() - 1);

            var d = fromDate.getDate();
            var m = fromDate.getMonth() + 1; //Month from 0 to 11
            var y = fromDate.getFullYear();

            let toDate = new Date(this.stockChart.rangeSelector.inputFields.startValue);
            toDate.setDate(toDate.getDate() + 1);
 

            var dT = toDate.getDate();
            var mT = toDate.getMonth() + 1; //Month from 0 to 11
            var yT = toDate.getFullYear();

            let fromDateStr = m + "/" + d + "/" + y;
            let toDateStr = mT + "/" + dT + "/" + yT; 

            const options = {
              method: 'GET', 
              url: '/pub/market/data/interval?market=' + this.$route.params.pair + "&interval=" + interval + "&fromDate=" + fromDateStr + "&toDate=" + toDateStr,
            };

            this.axios(options)
              .then((response) => {
                this.chartResponse = response.data;
                  let chartData = [];
                  let navigatorData = [];

                  response.data.forEach((el, i) => {
                    let obj = {};
                    obj.x = new Date(el.date);

                    obj.y = []; 
                    obj.y[0] = el.open;
                    obj.y[1] = el.high;
                    obj.y[2] = el.close;
                    obj.y[3] = el.low; 


                    chartData.push(obj)


 
                  })
 
                  //if(chartData.length > 0) {
                     this.stockChart.options.charts[0].data[0].dataPoints = [
      { x: new Date("2023-01-25T00:00:00"), y: [50, 70, 60, 65] },
      { x: new Date("2023-01-25T00:01:00"), y: [40, 60, 55, 55] },
      { x: new Date("2023-01-25T00:02:00"), y: [30, 40, 45, 40] }];
                    this.changeBorderColor(this.stockChart);
 
                    this.stockChart.render();
                  // } else {
                  //   this.renderChart(false);
                  // }

                 
            }) 
          }
 

          

        },

     renderChart: function(onload) {

      let symbol = "";

      switch(this.init.currency.toCurrency) {
        case "BTC":
          symbol = "INDEX:BTCUSD"
        break;

        case "ETH":
          symbol = "INDEX:ETHUSD"
        break;

      case "USDT":
          symbol = "CRYPTO:USDTUSD"
        break;

      case "BLX":
          symbol = "BLXUSDT"
        break;
      }
 

      if(symbol == 'BLXUSDT') {
         // if(onload) {
         //    this.stockChart = new CanvasJS.StockChart("chartContainer",{
         //              theme: "dark2", 
         //              colorSet: "purpleShades",
         //              backgroundColor: "#171B26",
         //              zoomEnabled: true, 
         //              rangeChanged: this.rangeChanged,
         //              title: {
         //                  text: this.marketPair.value + " - xCoin",
         //                  fontColor: "#B2B5BE",
         //                  padding: 2
         //                },
         //              charts: [{
                        
         //                axisX: {
         //                  labelFontColor: "#B2B5BE",
         //                  lineColor: "#B2B5BE"
         //                },
         //                axisY: { 
         //                   title: this.marketPair.value,
         //                   labelFontColor: "#B2B5BE", 
         //                   titleFontColor: "#B2B5BE", 
         //                  gridColor: "#313540",
         //                  valueFormatString: this.init.currency.fromCurrency + " ##0.00000000",

         //                },
         //                data: [{
         //                  risingColor: "#089981",
         //                  fallingColor: "#F23645", 
         //                  type: "candlestick",
         //                  yValueFormatString: this.init.currency.fromCurrency + " ##0.00000000",
         //                  dataPoints : []
         //                }]
         //              }],
         //              navigator: {
         //                enabled: false,
         //                 axisX: {
         //                  labelFontColor: "#B2B5BE"
         //                 },
         //                 slider: {
         //                  maskColor: "#4A4E59", 
         //                 }
                         
         //              },

         //              rangeSelector: {

         //                label: "", 

         //                inputFields: {
         //                  style: {
         //                    backgroundColor: "#171B26",
         //                    borderColor: "#B2B5BE",
         //                    borderColorOnFocus: "#B2B5BE",
         //                    fontSize: 11,
         //                    fontColor: "#B2B5BE"
         //                  }
         //                },
         //               buttonStyle: {
         //                 backgroundColor: "#171B26",
         //                 borderColor: "#B2B5BE",
         //                 backgroundColorOnHover: "#00020D",
         //                 backgroundColorOnSelect: "#4A4E59",
         //                  padding: 5,
         //                  labelFontSize: 11,
         //                  labelFontColor: "#B2B5BE"

         //               },

         //               buttons: [
         //                   {
         //                     label: "10s",
         //                     range: 10,
         //                     rangeType: "second"
         //                   },
         //                   {
         //                     label: "1m",
         //                     range: 1,
         //                     rangeType: "minute"
         //                   },
         //                   {
         //                     label: "3m",
         //                     range: 3,
         //                     rangeType: "minute"
         //                   },
         //                   {
         //                     label: "5m",
         //                     range: 5,
         //                     rangeType: "minute"
         //                   },
         //                   {
         //                     label: "15m",
         //                     range: 15,
         //                     rangeType: "minute"
         //                   },
         //                   {
         //                     label: "30m",
         //                     range: 30,
         //                     rangeType: "minute"
         //                   },
         //                   {
         //                     label: "1H",
         //                     range: 1,
         //                     rangeType: "hour"
         //                   },
         //                   {
         //                     label: "1D",
         //                     range: 1,
         //                     rangeType: "day"
         //                   },
         //                   {
         //                     label: "1W",
         //                     range: 1,
         //                     rangeType: "week"
         //                   },
         //                   {
         //                     label: "1M",
         //                     range: 1,
         //                     rangeType: "month"
         //                   },
         //                   {
         //                     label: "1Y",
         //                     range: 1,
         //                     rangeType: "year"
         //                   },
         //                   {
         //                     label: "ALL",
         //                     range: 1,
         //                     rangeType: "all"
         //                   }
         //                 ]

         //             }
         //            });
         // }
     
         

         // const options = {
         //      method: 'GET', 
         //      url: '/pub/market/data?market=' + this.$route.params.pair,
         //    };

         //    this.axios(options)
         //      .then((response) => {
         //        this.chartResponse = response.data;
         //          let chartData = [];
         //          let navigatorData = [];

         //          response.data.forEach((el, i) => {
         //            let obj = {};
         //            obj.x = new Date(el.date);

         //            obj.y = [];
         //             obj.y[0] = el.open;
         //            obj.y[1] = el.high;
         //            obj.y[2] = el.close;
         //            obj.y[3] = el.low; 


         //            chartData.push(obj)
 
         //          }) 
  
         //          this.stockChart.options.charts[0].data[0].dataPoints = chartData;
         //          this.changeBorderColor(this.stockChart);
         //           this.stockChart.render();  

                  
                  
         //        })


        var datafeed = {
 
            onReady: function(cb) {

              setTimeout(() => {
                cb({
                "supports_marks": false,
                "exchanges": [
                {
                "name": "xCoin PRO",
                "value": "XCOIN",
                "desc": "xCoin PRO"
                }
                ],
                "supports_group_request": false,
                "supports_search": true,
                "resolution": [
                "1","60","180","D", "2D", "3D", "W", "3W", "M", "6M"
                ],
                "supports_timescale_marks": false
                });
              }, 0)
                
            },
            searchSymbols: function(userInput, exchange, symbolType, onResultReadyCallback) {
                // your implementation here
                // For example, you can use an AJAX call to a server-side script that returns a list of symbols that match the user's input
            },
            resolveSymbol: function(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
                 fetch('/symbols?symbol=BLXUSDT')
                .then(response => response.json())
                 .then(data => { 

                     var symbolInfo = {
                              name: data.name,
                              description: data.description,
                              type: data.type,
                              session: data.session,
                              exchange: "XCOIN",
                              ticker: data.ticker,
                              minmov: data.minmov,
                              pricescale: data.pricescale,
                              timezone: data.timezone,
                              resolution: [
                    "1","60","180","D", "2D", "3D", "W", "3W", "M", "6M"
                    ],
                    has_intraday: true,
                    has_no_volume: false,

                          };

                          onSymbolResolvedCallback(symbolInfo);


                  });
            },
 
            getBars: function(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
 
              let interval = resolution;

              if(resolution == '1D') {
                interval = 1440;
              }

              let fromDate = new Date(periodParams.to * 1000)
                  fromDate.setFullYear(fromDate.getFullYear() - 1);
                  
                  var d = fromDate.getDate();
                  var m = fromDate.getMonth() + 1; //Month from 0 to 11
                  var y = fromDate.getFullYear();

                  let toDate = new Date(periodParams.to * 1000);
                  toDate.setDate(toDate.getDate() + 2);
       

                  var dT = toDate.getDate();
                  var mT = toDate.getMonth() + 1; //Month from 0 to 11
                  var yT = toDate.getFullYear();

                  let fromDateStr = m + "/" + d + "/" + y;
                  let toDateStr = mT + "/" + dT + "/" + yT; 


                // console.log("FROM: " + new Date(periodParams.from * 1000))
                // console.log("TO: " + new Date(periodParams.to * 1000))
                // console.log("countBack: " + periodParams.countBack)
                fetch('/pub/market/data/interval?market=BLXUSDT&interval='+ interval +'&fromDate=' + fromDateStr + '&toDate=' + toDateStr + "&empty=" + !periodParams.firstDataRequest)
                .then(response => response.json())
                 .then(data => {
  

                      try {
                        const bars = data.map((d) => ({
                          ...d,
                          time: (new Date(d.date).getTime()),
                          volume: 0
                        }));

 


                        if (bars?.length) {
                         if(periodParams.firstDataRequest) {
                           onHistoryCallback(bars, { noData: false });
                         }
                         
                        } else {
                          console.log('NO DATA', bars);
                          onHistoryCallback([], { noData: true });
                        }
                      } catch (error) {
                        console.log('[getBars]: methods', error);
                        onErrorCallback(error);
                      }
                         
                  }).catch((err) => {
                    console.log(err)
                    //onErrorCallback(err);

                  })




            },
            
            subscribeBars: function(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
                // your implementation here
                // For example, you can use WebSockets to receive real-time data for a specific symbol and interval
            },
            unsubscribeBars: function(subscriberUID) {
                // your implementation here
                // For example, you can close the WebSocket connection when the subscriber unsubscribes
            }
        };

        var widget = window.tvWidget = new TradingViewStandAlone.widget({
          "autosize": true,
          symbol: symbol,
          interval: '1D',
          container: "tradingview",

          //  BEWARE: no trailing slash is expected in feed URL
          // datafeed: new Datafeeds.UDFCompatibleDatafeed(datafeedUrl),
          datafeed: datafeed,
          minimalDataRequest: true,
          library_path: "assets/charting_library/",
          locale:"en",

          charts_storage_url: 'https://saveload.tradingview.com',
          charts_storage_api_version: "1.1",
          client_id: 'tradingview.com',
          user_id: 'public_user_id', 
          enable_publishing:false,
          style: "1",

          "timezone": "Asia/Singapore",
          "theme": "dark", 
          "disabled_features": ['widget_logo'],
        });

      } else { 
        
        setTimeout(() => {
          new TradingView.widget(
              {
              "autosize": true,
              "symbol": symbol,
              "interval": "D",
              "timezone": "Asia/Singapore",
              "theme": "dark",
              "style": "1",
              "locale": "en",
              "toolbar_bg": "#f1f3f6",
              "enable_publishing": false,
              "container_id": "tradingview",
              "disabled_features": ['widget_logo'],

            }
              );
        }, 1000)
      } 
      


     }
      
  },
  watch: {
    'marketPair': function(newValue, oldValue) {
       
          if(newValue.value !== oldValue.value) {   
              //this.$route.params.pair  = newValue;
              window.location.href = "/#/dashboard/" + newValue.value;
              //console.log(newValue)
              //location.reload();

              this.init.currency.fromCurrency = newValue.label.substring(newValue.label.indexOf('/') + 1)
              this.init.currency.toCurrency = newValue.label.substring(0, newValue.label.indexOf('/'))

              this.buyValues.quantity = ""
              this.buyValues.orderValue = 0;

              this.sellValues.quantity = ""
              this.sellValues.orderValue = 0;
              
              this.getRate();
              this.getBuyOrders('11');
              this.getBuyOrders('00');
              this.getSellOrders('11');
              this.getSellOrders('00');
              this.renderChart(true);
              this.getPairsDetails();
            }
      },
    },

  created() {

    
    this.subscribe();
  },

  beforeMount() {
    this.checkSession();
    this.getPairs();
    this.getBuyOrders('11');
    this.getBuyOrders('00');
    this.getSellOrders('11');
    this.getSellOrders('00'); 
  }
   
   
  
}
</script>


<style scoped>
  .market-price {
    font-weight: bold;
    font-size: 1.25em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .justify-content-end {
    display: flex;
    justify-content: end;
  }

  #left-bars {
    font-size: 80%;
  }

  .main-container {
    justify-content: space-between;
    padding: 1em;
  }
  .left-col {
    width: 50%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap; 
        padding: 0.25em;
  }

  .right-col {
    width: 50%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap; 
        padding: 0.25em;
  }

  #tradingview {
    height: 25em;
  }
  table {
    width: 100%;
  }
.mr-5 {
  margin-right:2em;
}
.navbar {
        background-color:#011434;
    }

    #btnLogin {
        background-color:#9999ff;
        color:white;
    }

    #btnSignup {
        background-color:#361c56;
        color:white;
    }

    #bar {
        background-color:#021642;
        padding:15px;
    }

    #bar h4 {
        color:#bfc0c1;
        font-size:12px;
        font-family:MSR-Reg;
    }

    #main-body {
        background-color:#011434;
        padding:50px;
    }

    #main-center {
      width: 49%;
    }

    #main-left {
      width:24%;
    }

      #main-right {
      width: 24%;
      background-image:linear-gradient(to bottom right, #361c56, #1b0c47);
      border-radius:10px;
       
      padding:30px;
      color:white;
      font-family:MSR-Reg;
      font-size: 80%;
    }


    #main-topbar {
        background-image: linear-gradient(#7c2983, #3a005d);
        color:white;
        padding:10px;
        border-radius:10px;
    }

    .pair-select {
      background-color:none;
    }

    .topbar-div h1 {
        font-size:24px;
        font-family:MSR-Bold;
    }

    .topbar-div h4 {
        font-size:20px;
    }

    .topbar-div h4 {
        font-size:20px;
        color:#8585e5;
        font-family:MSR-SemiBold;
    }

    .topbar-div h6 {
        font-family:MSR-Reg;
    }

    .topbar-div .small-header {
        font-family:MSR-Medium;
        color:#8c8c8c;
    }

    .topbar-div .purple-text {
        color:#8585e5;
    }

    #left-bar-1 {
        background-image:linear-gradient(to bottom right, #361c56, #1b0c47);
        color:#8c8c8c;
        font-family:MSR-Medium;
        padding:20px;
        border-radius:10px; 
    }

    #price-column, #amount-column, #total-column {
        width:30em;
    }

    #amount-column h6, #total-column h6 {
        text-align: right !important;
        justify-content: right !important;
        align-items: right !important;
    }

  
    #buy-price-column, #buy-quantity-column {
      text-align: left !important;
    }

    .buy-price-header, .buy-quantity-header, .sell-price-header, .sell-quantity-header {
      text-align: left;
      font-family:MSR-Bold;
    }

    .buy-price-header, .buy-price-text, .sell-price-header, .sell-price-text {
      color:#c906f9;
    }

    #main-bottom {
      background-image:linear-gradient(to bottom right, #361c56, #1b0c47);
      color:white;
      
      padding:20px;
      border-radius:10px;
      font-family:MSR-Reg;
    }
    
    .buy-or-sell {
      width:100%;
    }

    .buy-or-sell button {
      color: #FFF;  
      width:50%;
      border:2px solid #452469;
      border-radius:10px;
    }

    .buy-or-sell .active {
      background-color:#452469;
    }

    .bottom-field {
      background-color:#452469;
      padding:12px;
      border-radius:10px;
    }

    .field-label {
      color:#8c8c8c;
    }

    .field-text {
      font-family:MSR-Medium;
    }

    .btn-buy {
      background-image:linear-gradient(to right, #361c56, #452469);
      margin-top:1em;
      width:100%;
      color:#9999ff;
      padding:10px;
      border-radius:10px;
    }

    .btn-buy:hover {
      color:#9999ff;
    }

    #main-center-top {
      /*background-image:linear-gradient(to bottom right, #361c56, #1b0c47);*/
      background-color: #171B26;
      color:white; 
      padding:20px;
      border-radius:10px;
      font-family:MSR-Reg;
      margin-bottom:1em;
    }

    ::v-deep .dropdownBtn {
      color: #fff!important;
    }

    /*sm*/
  @media only screen and (max-width: 640px) {
    .main-container {
      flex-direction: column;
    }

    #main-topbar {
      margin: 1em ;
    }
    #main-left {
      width: 100%;
      order: 2;
    }
    #main-center {
      width: 100%;
      order: 1;
    }

    #main-right {
      width: 100%;
      order: 3;
    }

    #main-body {
      font-size: 90%;
      padding: 0.25em;
    }
    label {
      font-size: 90%;
    }

    #left-bar-1 {
      margin-top: 1em;
    }
    #main-right {
      margin-top: 1em;
    }
  }

  /*med*/
  @media only screen and (min-width: 641px) and (max-width: 1007px) {
     .main-container {
      flex-direction: column;
    }

    #main-topbar {
      margin: 1em ;
    }
    #main-left {
      width: 100%;
      order: 2;
    }
    #main-center {
      width: 100%;
      order: 1;
    }

    #main-right {
      width: 100%;
      order: 3;
    }

    #main-body {
      font-size: 90%;
      padding: 0.25em;
    }
    label {
      font-size: 90%;
    }

    #left-bar-1 {
      margin-top: 1em;
    }
    #main-right {
      margin-top: 1em;
    }
  }
.column {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row ;
  justify-content: space-evenly;
   /* border: 1px solid black; */
}
.row {
    margin: 0 -5px;
    display: flex !important;
    color: white !important;
}
.row:after {
  content: "";
  display: table;
  clear: both;
  
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  padding: 5px;
  text-align: center;
  background: transparent;
    backdrop-filter:blur(20px);
	/* box-shadow:0 0 30px rgba(0,0,0,0.3); */
	/* border: 2px solid #8585e5; */
  display: flex;
  color: white !important;
  width: auto;
  margin: 10px;
  
}

.routelink:hover {
  text-decoration: underline;
}

</style>
