<template>
  <div class="backdrop">
      <div class="b-modal">
        <div class="modal-header">
          <div class="modal-title">
            <img src="@/assets/images/logo.png" />
          </div>
          <p class="text-white"><span @click.self="closeModal">x</span></p>
        </div>
        <div>

            <form class="form" v-on:submit.prevent="onSignup" v-if="login.state == 'login'">
                <h4 class="text-center text-dark">REGISTER</h4>
                <div class="danger" v-if="errors.login.show">
                    {{ errors.login.msg }}
                </div>
                <div class="form-row container text-center">
                    <div class="btn-group text-center ">
                        <button class="btn mobile-btn mx-2 mr-sm-2" type="button"  v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')" >Mobile Number</button>
                        <button class="btn email-btn mr-sm-2" type="button" v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button>
                    </div>
                </div>
                <div class="form-group" v-if="selected =='email'">
                    <label for="formGroupExampleInput">Enter your Email Address:</label>
                    <input type="text" class="form-control mb-3" id="formGroupExampleInput" v-model="login.loginId">
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>

               <div class="form-group mobile-container" v-if="selected =='mobile' ">
                    <label for="loginId">Enter your Mobile Number:</label> 
                    <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn" type="button" v-on:click="toggleCountryList">
                        <img class="default-flag" :src="require('../../assets/signup_flags/' + country + '.png' )">
                        <span class="text-dark">{{ mobileCode }}</span>
                      </button>
                    </div>
                    <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
                  </div>
        
                  <div class="country-list" v-if="showCountry">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l" v-on:click="selectCountry(l)">
                          <div >
                            <img class="flag-list-img" :src="require('../../assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
        
                          <div class="country-name"> <span> {{ l.country }} </span> </div>
                          
                        </div>
                        
                      </div>
                    </div>
                     <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>
        
        
        
                <div class="form-group">
                    <label for="exampleFormControlInput1">Enter you 6-digit PIN for Security:</label>
        
                    <div class="input-group mb-3">
                      <input type="password" class="form-control" id="inputPass" @keypress="isNumber($event)" maxlength="6" v-model="login.loginPass">
                      <div class="input-group-append">
                        <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword">{{ hideOrShow}}</button>
                      </div>
                    </div>
                    <p class="help-block" v-if="errors.login.loginPass && errors.login.loginPass !== ''"> {{ errors.login.loginPass }} </p>
        
                </div>


                <label for="exampleFormControlInput1">Answer Math Equation Correctly:</label>
                <div id="captcha" class="d-flex justify-content-center mx-auto">
                  
                    <div>
                        <img :src="`data:image/png;base64,${captchaString}`"  id="captchaImg"/>
                    </div>
                    <div class="captcha">
                        <input type="text" class="form-control text-center text-input captcha" v-model="captchaSum"  @keypress="isNumber($event)">
                    </div>
                </div>

                
                <div class="form-group ">
                    <button type="submit" class="btn btn-main" id="loginBtn">SIGN UP</button>
                </div>
        
                <div class="form-group ">
                  
                    <button type="button" class="btn btn-primary signupBtn" v-on:click="showLogin()">Already have an account? LOG IN</button>
                </div>

            </form>

            <form v-if="login.state == 'otp'"  v-on:submit.prevent="verifyOtp" class="form">
                <h4 class="text-center text-dark">AUTHENTICATION</h4>
        
                <h6 class="text-center text-dark">Authentication code has been sent to your mobile number or email address. Kindly enter below:</h6>
        
                <div class="otp-wrapper text-center" >
                  <div>
                    <input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
                  </div>
        
                  <div>
                    <input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
                  </div>
        
                  <div>
                    <input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
                  </div>
        
                  <div>
                    <input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
                  </div>
        
                  <div>
                    <input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
                  </div>
        
                  <div>
                    <input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
                  </div>
                </div>
                <p class="help-block" v-if="errors.login.otp && errors.login.otp !== ''"> {{ errors.login.otp }}</p> 
                <p class="help-block" v-if="errors.login.msg && errors.login.msg !== ''"> {{ errors.login.msg }}</p>

                 <div class="text-center text-dark">
                  <p class="text-dark" @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
                  <p class="text-dark" v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
                </div>


                <div class="form-group ">
                    <button type="submit" class="btn btn-main ">SUBMIT</button>
                </div>
        
                <div class="form-group ">
                    <button type="button" class="btn signupBtn" v-on:click="login.state = 'login'">CANCEL</button>
                </div>
        
                <div class="footer">
                    <span>&copy; xCoin.net</span>
                </div>
              </form>
            </div>
    </div>
  </div>

</template>


<script>
  import qs from 'querystring'
  export default {
    name: 'Signup',
    components:{
    },
    data: function() {
      return  {
        userId:"",
        otpArr: [], 
        selected: 'mobile',
        country: 'PH',
        mobileCode: '63',
        showCountry: false,
        login: {
          state: 'login',
          loginPass: "",
          loginId: ""
        },
        errors: {
          login: {
            msg: "",
            show: false,
            loginId: "",
            otp: "",
            loginPass: ""
          }
        },
        list: {
          mobileCodes: []
        },
         hideOrShow: "Show",
        showRegistered: false,
        captchaString: "",
        captchaSum: "",
        captchaImgSrc: "",
        ifResent: true,
    timerInSeconds: 60,
      }
    },
    methods: {
       timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },
       resendCode: function() {
        let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }

        const params = {
          signupHost: "xcoin-trade.blx.live",
          loginId: loginId,
          authzCode: this.login.loginPass,
          captcha: this.captchaSum
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data,
            url: '/cx/signup/2fa/resend',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },



      captcha() {
            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/signup/captcha?fontColor=4e1163',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.captchaString = response.data
                })
                .catch((error) => {
                    // Error code goes here
                    this.errors.login.msg = error.response.data.msgText

                    setTimeout(()=>{
                      this.clearError()
                    },4000);
                    
                });
        },
      showLogin(){
        this.$emit("openLogin");
      },
      closeModal() {
        this.$emit("close");
      },
     
      toggleCountryList: function(){
        this.showCountry = !this.showCountry;
      },
      toggleShowPassword: function(){
        this.login.showPassword = !this.login.showPassword;

        if(this.login.showPassword) {
          document.getElementById('inputPass').type = 'text';
          this.hideOrShow = "Hide"
        } else {
          document.getElementById('inputPass').type = 'password';
          this.hideOrShow = "Show"
        }
      },
      toggleLoginOption(selected) {
        this.selected = selected;
        this.login.loginId = "";

        this.errors.login.loginId = '';
        this.errors.login.loginPass = '';

        if(selected == 'email') {
          this.login.loginId = "";
        }
      },
      getMobileCodes: function() {
        this.axios.get('/assets/mobilecodes.json')
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);
              

          })
      },
      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode; 
      },
      validateEmail: function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      clearError(){
                this.errors.login.loginId = ""
                this.errors.login.loginPass = ""
                this.errors.login.msg = ""


      },

       timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },


      onSignup: function() {

        let hasError = false;

        if(this.selected == 'mobile') {
            if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
              this.errors.login.loginId = "Please provide a valid  Mobile number.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
            } 

            if(this.login.loginId.length < 10) {
              this.errors.login.loginId = "Please provide a valid  Mobile number.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
            } 

            if(this.login.loginId.substring(0,1) === '0') {
              this.errors.login.loginId = "Please remove 0 in front of your mobile number";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
            }
        } 

         if(this.selected == 'email') {
          if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
              this.errors.login.loginId = "Please provide a valid Email Address.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
            } 

            if(!this.validateEmail(this.login.loginId)) {
              
              this.errors.login.loginId = "Please provide a valid Email Address.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
            }
         }
        

        if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
          this.errors.login.loginPass = "Please provide PIN.";
          hasError = true;
          setTimeout(()=>{
                      this.clearError()
                    },4000);
        } else {
          if(this.login.loginPass.length < 6) {
            this.errors.login.loginPass = "PIN must be 6 digits.";
          hasError = true;
          setTimeout(()=>{
                      this.clearError()
                    },4000);
          }
        }

        

        if(hasError) {
          return;
        }

        this.errors.login.show = false;

        let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }
        
        const params = {
          signupHost: "xcoin-trade.blx.live",
          loginId: loginId,
          authzCode: this.login.loginPass,
          captcha: this.captchaSum
         
            }

        const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded',
           },
          data,
          url: '/cx/signup/pin',
            };

            this.axios(options)
              .then((response) => {
                console.log(response.data)
 
               this.login.state = 'otp';

               if(this.timerInSeconds == 60) {
                this.timer();
              }

           }).catch((err) => {
                this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                 
          })
      },

      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {

        if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
      },
      verifyOtp :function(){

        let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }


        let pin = this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5];

        this.axios.post('/cx/signup/2fa/verify', qs.stringify({
            signupHost: 'xcoin-trade.blx.live',
            loginId: loginId,
            otp: pin
          }))
          .then((response) => {
            localStorage.setItem("tokenId",response.data.tokenId); 
            localStorage.setItem("userId", response.data.userId)
            this.userId = localStorage.getItem("userId")
            this.$router.push({name: 'SetupProfile'})
            // this.$router.push({name: 'Dashboard', params: { userId: this.userId}})
            console.log(response.data)


          }).catch((err) => {
              this.errors.login = {}; 
              console.log(err.response.data)
              this.errors.login.show = true;
                 if (err.response.data) { 
                   
                if(err.response.data.fieldErrors) {

                  err.response.data.fieldErrors.forEach((error) => {
                    
                    switch (error.field) {


                      case 'loginId': 
                        this.errors.login.loginId = error.defaultMessage;

                      break;

                      case 'authzCode': 
                        this.errors.login.otp = error.defaultMessage;
                      break;
                    }
                  });


 
                }  else {
                console.log(err.response.data.msgText)
                  this.errors.login.msg = err.response.data.msgText;
                }
              } 
 
          })
      },
    },

    beforeMount(){
      this.getMobileCodes();
      
    },
    mounted(){
      this.captcha();
    },

    watch: {

    }

    }
</script>

<style scoped>
 .b-modal{
    width: 500px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
    padding-bottom: 50px;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
      background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
  }
.modal-title{
    margin: 0 auto;
    justify-content: center;
  }
.modal-title img{
    width: 4rem;
  }
  .alert-danger{
    background-color: none !important;
    border: none !important;
  }
.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
.b-modal h4{
    padding: 15px;
  }
    .logo{
        display: flex;
        justify-content: center;
        margin: 50px auto !important;
    }
    .logo img{
        width: 10rem;
    }

    .b-card{
      width: 50em;
    }

    .email-btn{
        background-color: transparent !important;
        border: transparent !important;
        font-size: 1rem !important;
        padding: 10px 10px;
        color: black !important;
        }
    .email-btn:hover{
        color: #7000a3 !important;
    }
    .email-btn:focus{
        border-bottom: solid 1px #7000a3 !important;
        outline: none !important;
        color: #7000a3 !important;
    }
    .email-btn.active{
        border-bottom: solid 1px #7000a3 !important;
        outline: none !important;
        color: #7000a3 !important;
    }
    .mobile-btn{
        background-color: transparent !important;
        border-color: transparent !important;
        font-size: 1rem !important;
        padding: 10px 20px;
        color: black;
    }
    .mobile-btn:hover{
        color: #7000a3 !important;
    }
    .mobile-btn:focus{
        border-bottom: solid 1px #7000a3 !important;
    }
    .mobile-btn.active{
        border-bottom: solid 1px #7000a3 !important;
        outline: none !important;
        color: #7000a3 !important;
    }
    .form{
        width: 80%;
        display: block;
        justify-content: center;
        margin: 0 auto !important;
    }

    .signupBtn {
        border: 1px solid #481a69;
        color: black;
        width: 100%;
        margin-top: 0.5em;
    }
    .signupBtn:hover{
        background-color:#481a69;
        color: white;
    }
    .btn-main{
        border: 1px solid #481a69;
        color: black;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }
    .forgot {
        display: flex;
        justify-content: end;
    }
    a {
        color: #481a69;
        text-decoration: underline;
    }
    .form-control{
        background-color: lightgray;
    }
    .mobile-container {
        position: relative;  
    }
    .default-flag {
        width: 33px;
        margin-right:0.25em;
    }
    .btn{
        border: 1px solid #481a69;
        
    }
    .country-list {
        position: absolute;
        background: #ccc;
        color: #481a69;
        top: 70px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 3;
    }
    .country-list .row {
        margin: 0 !important;
    }

    .country-list .col-sm-6 {
      display: flex;
      padding: 0.7em;
      align-items: center;
      cursor: pointer;
    }
    .country-list .row:hover {
      background: darken(#481a69, 10%);
    }
    .country-name {
      margin-left: 0.5em;
    }
    .country-name span {
        font-size: 0.9em;
    }

    .flag-list-img {
        width: 25px;
    }
    .email-or-mobile {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1em;
    }
    .email-or-mobile .btn {
          color: #481a69;
          border: none;
          outline: none;
          box-shadow: none;
          border-radius: 0;
    }
    .btn-primary{
      background-color: #481a69;
      color: white;
    }
    .email-or-mobile .btn.active {
        color: #481a69;
        border-bottom: 1px solid #481a69;
      }
    
    .email-or-mobile .button {
        color: #481a69;  
    }

    .wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2em;
        width: 25em;
      }
    .wrapper form {
        color: #FFF;
    }
    .otp-wrapper {
        align-items: center;
        display: flex;
        width: 60%;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 1em;
    } 
    .otp-wrapper div {
        margin: 0 .25em;
    }
    .otp-wrapper .otp-input {
        width: 47px;          
        text-align: center;
        border-radius: 6px;
        box-shadow: none;
        font-size: 2em; 
        border: 1px solid  #262d33;
        box-shadow: none;
        outline: none; 
        background: #FFFFFF;
        color: #262d33;
        border-radius: 6px;
        height: 60px;
    }
    .footer{
      display: flex;
      justify-content: center;
      bottom: 0 !important;
    }
     	@media screen and (min-width: 280px) and (max-width: 768px) {
        .b-modal{
          width: 300px;
          scroll-behavior: auto;
          margin: 10px auto;
        }
        .mobile-container .text-center, label{
          font-size: 10px !important;
        }
        #captcha img{
          width: 8rem !important;
        }
        .captcha .form-control {
          padding: 5px !important;
          border: 1px solid #7000a3;
          border-radius: 4px;
          font-size: 1rem !important;
        }

        .otp-wrapper .otp-input {
              width: 40px;
              height: 50px;
        }
      }

</style>