<template>

<div class="bg">
  <PageLoader v-if="showLoading"/>
    <div class="e-modal container text-white">
        <div class="b-card">
            <!-- <Timer/> -->
            <h4 class="pt-5">Complete KYC Information</h4>
                <div class="alert alert-danger" v-if="errors.profile.show">
                    {{ errors.profile.msg }}
                </div>

            <form class="form" v-on:submit.prevent="submitKYC()" v-if="!isSubmitted">
                <div class="form-group">
                    <label for="firstName">First Name:</label>
                    <input type="text" class="form-control mb-3"  id="firstName" v-model="profile.firstName" readonly>      
                    <label for="middleName">Middle Name:</label>
                    <input type="text" class="form-control mb-3"  id="middleName" @keypress="middleNamePersistence($event)" v-model="profile.middleName" required>

                    <label for="lastName">Last Name:</label>
                    <input type="text" class="form-control mb-3"  id="lastName" v-model="profile.lastName" readonly>   

                     <div class="form-group col">
                        <label for="inputMobile">Mobile Number</label>
                            <div class="input-group mb-3">
                            <div class="input-group-prepend" v-if="!hasMobile">
                            <button class="btn" type="button" v-on:click="toggleCountryList">
                                <img class="default-flag" :src="require('../../assets/signup_flags/' + country + '.png' )">
                                <span class="text-white">{{ mobileCode }}</span>
                            </button>
                            </div>
                            <input type="text" class="form-control"   v-model="profile.mobileNumber" :disabled="hasMobile" @keypress="isNumber($event), mobilePersistence($event)" required>
                        </div>
                
                        <div class="country-list" v-if="showCountry">
                            <div class="row">
                                <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)">
                                <div >
                                    <img class="flag-list-img" :src="require('../../assets/signup_flags/' + l.countryCode + '.png' )">
                                </div>
                
                                <div class="country-name"> <span> {{ l.country }} </span> </div>
                                
                                </div>
                                
                            </div>
                            </div>
                    </div> 

                    <label for="emailAddress">Email Address:</label>
                    <input type="text" class="form-control mb-3"  id="emailAddress" @keypress="emailPersistence($event)" v-model="profile.emailAddress" :disabled="!hasMobile">   

                    <!-- <p class="mx-4"><label >Birthdate: </label></p>
                        <div class="form-group d-flex justify-content-evenly">
                            <div class="d-block">
                                <label for="address"> Day: <strong>*</strong></label>
                                 <input type="text" class="form-control"  id="bdate" v-model="bdate.day">  
                            </div>
                            <div class="d-block">
                                <label for="address"> Month: <strong>*</strong></label>
                                <b-form-select class=" form-control" v-model="bdate.month" :options="months"></b-form-select>
                            </div>
                            <div class="d-block">
                                <label for="address"> Year: <strong>*</strong></label>
                                    <b-form-select class=" form-control" v-model="bdate.year" :options="years"></b-form-select>
                                
                            </div>

                        </div> -->

                    
                    <label for="exampleFormControlSelect1">Country</label>
                        <select class="form-control" id="exampleFormControlSelect1" v-model="countryName" @change="getCountryCode" required>
                            <option v-for="l in list.mobileCodes" :key="l.id" >{{ l.country }}</option>
                        </select>

                    <div class="mt-2" >
                        <label for="">Upload ID:</label>
                        <button required type="button" class="text-white btn mb-3 upload" @click="openUpload" v-bind:disabled="list.isValidId">Upload</button><span><b-icon v-if="list.isValidId" icon="check"></b-icon></span>
				             </div>
                     <div class="mt-2">
                        <label for="">Upload Selfie:</label>
                        <button required type="button" class="text-white btn mb-3 upload" @click="openUploadSelfie" v-bind:disabled="list.isSelfie">Upload</button><span><b-icon v-if="list.isSelfie" icon="check"></b-icon></span>
				             </div>
                     <div class="mt-2">
                        <label for="">Upload Proof of Address:</label>
                        <button required type="button" class="text-white btn mb-3 upload" @click="openUploadPoa" v-bind:disabled="list.isPoa">Upload</button><span><b-icon v-if="list.isPoa" icon="check"></b-icon></span>
				             </div>

                   
                </div>
                <div class="d-flex justify-content-center form-group ">
                    <button class="btn btn-main" id="saveBtn" @click="exitModal">Cancel</button>
                    <button type="submit" class="btn btn-main" id="saveBtn" :disabled="!list.isValidId || !list.isSelfie || !list.isPoa">Submit</button>
                </div>
              </form>
              <form class="form" v-if="isSubmitted">
                <div class="form-group">
                  Your KYC has been submitted. Kindly wait for 2-3 business days for verification.
                </div>
                <div class="d-flex  ">
                    <button class="btn btn-main" id="saveBtn" @click="exitModal">DONE</button>
                   
                </div>

              </form>
              
            </div>
            
    </div>
    <Upload v-if="showUpload" @close="openUpload"></Upload>
    <UploadPoa v-if="showUploadPoa" @close="openUploadPoa"></UploadPoa>
    <UploadSelfie v-if="showUploadSelfie" @close="openUploadSelfie"></UploadSelfie>

</div>
</template>

<script>

  import Upload from '../modals/Upload.vue'
  import UploadPoa from '../modals/UploadPoa.vue'
  import UploadSelfie from '../modals/UploadSelfie.vue'
  import PageLoader from '../../components/PageLoader.vue'



  export default {
    name: 'Kyc',
    components: {
        
        Upload,
        UploadPoa,
        UploadSelfie,
        PageLoader
      

    },
    data: function() {
      return  {
        showLoading: true,
        userId: "",
        profile: {
          firstName: "",
          lastName: "",
          middleName:"",
          bdate:{
                day: '',
                month: '',
                year: '',
          },
          
          emailAddress:"",
          mobileNumber:"",
          otp:"",
          permanentAddrSameAsPresent: true,
          permanentAddress:{
              addressExtraInfo:"yes",
              cityTown:"yes",
              numberStreet:"yes",
              postaCode:"yes",
              provState:"yes",
          }
        },
        uploadId: '',
        uploadSelfie: '',
        uploadPoa: '',
        country: 'PH',
        mobileCode: '63',
        showCountry: false,
        countryName:'Philippines',
        errors: {
          profile: {
            show:false,
            msg: "",
            
          },
        },
        showUpload: false,
        showUploadPoa: false,
        showUploadSelfie: false,
        showExitModal: false,
        age:'',
        list: {
          mobileCodes: []
        },
        isSubmitted: false,
        months: [
					{text: 'January', value: 'January'}, 
					{text:'February',value: 'February'},
					{text:'March',value: 'March' },
					{text:'April',value: 'April' },
					{text:'May', value: 'May'},
					{text:'June', value: 'June'},
					{text:'July', value: 'July'},
					{text:'August',value: 'August'},
					{text:'September', value: 'September'},
					{text:'October', value: 'October'},
					{text:'November', value: 'November'},
					{text:'December', value: 'December'}
					],
          uploadedId:[],
          list:{
            isValidId: false,
            isSelfie: false,
            isPoa: false
          },
          hasMobile: false,
      }
    },
    computed: {
      init() {
        return this.$store.state.init;
      },
      show() {
        return this.$store.state.show;
      },
      

    },
   
     watch: {
        

        'areaCode': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.mobileNumber = ''
            }
        },
       
    },
    methods: {
       getUploaded(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/poids/',
          };

          this.axios(options)
            .then((response) => {
              console.log(response.data)
              this.uploadedId = response.data

              this.uploadedId.forEach((el, i) => {
              
              //  this.list.uploaded = el.poidDesc
              if(el.poidDesc == 'validId'){
                this.list.isValidId = true
              }
                if(el.poidDesc == 'selfie'){
                this.list.isSelfie = true
              }
                if(el.poidDesc == 'poa'){
                this.list.isPoa = true
              }

              })


            })
       },
        openUpload(){
            this.showUpload = !this.showUpload
        },
         openUploadPoa(){
            this.showUploadPoa = !this.showUploadPoa
        },
         openUploadSelfie(){
            this.showUploadSelfie = !this.showUploadSelfie
        },
        exitModal() {
             window.location.href = "/#/dashboard/BLXUSDT"
        },
        toggleCountryList: function(){
            this.showCountry = !this.showCountry;
        },
        
       
        getCountryCode() {
            console.log('...')
            this.list.mobileCodes.forEach((value, index) => {
                if(this.countryName == value.country) {
                    this.country = value.countryCode
                    this.mobileCode = value.mobileCode
                    console.log(this.country)
                }
            })
        },
        getMobileCodes: function() {
        this.axios.get('/assets/mobilecodes.json')
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);

          })
        },
        selectCountry: function(c) {
            this.showCountry = false;
            this.country = c.countryCode;
            this.mobileCode = c.mobileCode; 
        },
        
        getProfile: function(){
            
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               this.init.profile = response.data;
               this.userId = response.data.entityId
               this.profile.emailAddress = response.data.emailAddress
               this.profile.firstName = response.data.firstName
               this.profile.lastName = response.data.lastName
               this.profile.bdate = response.data.birthDate
               this.profile.mobilePhone = response.data.mobilePhone;
               this.profile.mobileNumber = response.data.mobilePhone;
               this.profile.middleName = response.data.middleName
             

               let middleName = localStorage.getItem("m");
                 
                 if(middleName != null) {
                    this.profile.middleName = middleName;
                 }

                 let emailAddress = localStorage.getItem("e");
                 
                 if(emailAddress != null) {
                    this.profile.emailAddress = emailAddress;
                 }

                 let mobileNumber = localStorage.getItem("p");
                 
                 if(mobileNumber != null) {
                    this.profile.mobileNumber = mobileNumber;
                 }



               if(this.init.profile.middleName != null) {
                 this.isSubmitted = true
               }

               if(response.data.mobilePhone != null) {
                this.hasMobile = true
               } else if(response.data.emailAddress != null){
                this.hasMobile = false
               }

                              this.showLoading = false

              })
        },
        checkSession: function() {

            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                setTimeout( () => {
                    this.showLoading = false;
                },1000)
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/#/";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/#/profile/setup";
                },1000)
                    
                }  

                })
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
                } else {
                return true;
                }
            },

            middleNamePersistence: function(evt) {
                localStorage.setItem("m", this.profile.middleName)
            },
            mobilePersistence: function(evt) {
                localStorage.setItem("p", this.profile.mobileNumber)
            },
            emailPersistence: function(evt) {
                localStorage.setItem("e", this.profile.emailAddress)
            },
                      
            
            submitKYC: function(){
              

                if(this.profile.mobileNumber == "" || this.profile.mobileNumber == null || this.profile.mobileNumber == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Mobile Number is required";
                return false;
                } 

                if(this.profile.emailAddress == "" || this.profile.emailAddress == null || this.profile.emailAddress == undefined)  {
                this.errors.profile.show = true;
                this.errors.profile.msg = "Email Address is required";
                return false;
                } 

                setTimeout(()=> {
                  this.errors.profile.msg = "";
                  this.errors.profile.show = false;
                }, 5000)

                this.errors.profile.show = false;
                
                let mobilePhone = this.mobileCode + this.profile.mobileNumber;


                if(this.mobileCode == this.profile.mobileNumber.substr(0,this.mobileCode.length) ) {
                    mobilePhone = this.profile.mobileNumber;
                }

                const params = {
                    birthYear: '2000',
                    birthMm: '1',
                    birthDd: '1',
                    firstName: this.profile.firstName,
                    lastName: this.profile.lastName,
                    middleName: this.profile.middleName,
                    emailAddress:this.profile.emailAddress,
                    mobilePhone: mobilePhone,
                    birthYear: '2004',
                    birthMm: '01',
                    birthDd: '01',
                    otp:this.profile.otp,
                    permanentAddrSameAsPresent: true,
                    "presentAddress.numberStreet": '1',
                    "presentAddress.cityTown": '1',
                    "presentAddress.provState": '1',
                    "presentAddress.postalCode": '1',
                    "presentAddress.country": this.country,
                    


                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


                const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
                data,
                url: '/cx/submitbasicinfo',
                };

                this.axios(options)
                .then((response) => {

                    localStorage.removeItem("m");
                    localStorage.removeItem("p");
                    localStorage.removeItem("e");

                    console.log(response.data)
                        this.errors.profile.msg = "";
                        this.errors.profile.show = false;
                    if(emailAddress == null && mobileNumber == null && this.profile.birthDate == null && this.uploadId != '200' && this.uploadSelfie != '200' && this.uploadPoa != '200' ){
                        this.this.errors.profile.show = true
                    }
                     else {
                        this.isSubmitted = true
                    }

                }).catch((err) => {
                console.log(err.response.data)     
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.msg
                   if (err.response.data) { 

		                  if(err.response.data.fieldErrors) {
                        console.log(err.response.data.fieldErrors)

		                    err.response.data.fieldErrors.forEach((error) => {

		                      switch (error.field) {

		                        default:
		                          this.errors.profile.msg  = error.defaultMessage;
                              

		                        break;
                            

		                      }
                          console.log(error.defaultMessage)
		                    });

		                  }  else {

		                    this.errors.profile.msg = err.response.data.msgText;
		                  }
                      setTimeout(()=> {
                        this.errors.profile.msg = "";
                        this.errors.profile.show = false;
                      }, 5000)
		                }
                })
            },
            uploadIdOk: function(){
              this.uploadId = localStorage.getItem("uploadId");
            },
            uploadSelfieOk: function(){
              this.uploadSelfie = localStorage.getItem("selfie");
            },
            uploadPoaOk: function(){
              this.uploadPoa = localStorage.getItem("poa");
            },




  

     },

   mounted(){
    //  this.uploadIdOk();
    //  this.uploadSelfieOk();
    //  this.uploadPoaOk();
     this.getUploaded();
     this.getProfile();

     

     
     
   },
   beforeMount(){
       this.getMobileCodes();
   }
 }
</script>

<style scoped>
    .bg{
      background: rgb(2,11,54);
      width: 100%;
      height: auto !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
   }
    .logo{
        justify-content: center;
        text-align: center;
        display: flex !important;
    }
    .logo img{
        background-color: transparent;
        width: 7rem;
    }
    .bdate{
        padding: 20px 10px;
    }
    .birthday-from-group .col{
        margin-bottom: 0.5em;
        display: flex;
        justify-content: space-evenly;
        width: 35%;
    }
    .e-modal{
            margin-top: 20px;
            background-image: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-bottom: 5em;
            width: 50em;
            border-radius: 10px !important;
            box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.336), 0 9px 46px 8px rgba(0, 0, 0, 0.356), 0 11px 15px -7px rgba(0, 0, 0, 0.295)
        }

    .form{
        padding: 20px;
        width: 100%;
        display: block;
        justify-content: center !important;
        text-align: center;
        margin: 0 auto !important;
    }
    .form-group{
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
    

    .btn-main{
        margin: 30px auto;
        border: 1px solid #481a69;
        color: white;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }
    .upload{
        border: 1px solid #481a69;
        margin: 10px;
    }
      .icon{
        border: none;
    }
    .flag-list-img {
        width: 33px;
    }

    .mobile-container {
        position: relative;
    }

    .default-flag {
        width: 33px;
    }

    .country-list {
        position: absolute;
        background: #ccc;
        color: #481a69;
        top: 170px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 3;
    }
    .country-list .row {
        margin: 0 !important;
    }

    .country-list .col-sm-6 {
      display: flex;
      padding: 0.7em;
      align-items: center;
      cursor: pointer;
    }
    .country-list .row:hover {
      background: darken(#481a69, 10%);
    }
    .row {
        margin: 0 !important;
    }
    .col-sm-6 {
        display: flex;
        padding: 1em;
        align-items: center;
        cursor: pointer;
    }
    .col-sm-6:hover {
        background: darken(#3468c7, 10%);
    }
    .country-name {
        margin-left: 0.5em;
    }

    span {
        font-size: 0.9em;
    }
    .flag-btn {
        width: 40px;
        border-radius: 3px;
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none !important;
        border-radius: 0;
    }
     h6{
         padding-right: 5px;
    }

    .code {
        background-color:#efefef;
        width:125px;
        font-size:15px;
        
    }
    .alert-danger{
        background-color: none !important;
        border: none !important;
    }

     @media only screen and (max-device-width: 580px) {
          .e-modal{
            margin-top: 20px;
            background-image: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-bottom: 5em;
            width: 20em;
            border-radius: 10px !important;
            box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.336), 0 9px 46px 8px rgba(0, 0, 0, 0.356), 0 11px 15px -7px rgba(0, 0, 0, 0.295)
        }

     }


</style>