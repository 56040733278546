import Vue from 'vue';
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex, axios);

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    
    
    init: {
      availableBalance:{},
      user: {
        firstName: '',
        lastName: '',
        fullName:''
      },
      currency: {
        fromCurrency: "",
        toCurrency: ""
      },
      balance: {},
      history: {},
      fundHistory: []
    },
    

  },


  modules: {
  }
 
});